<template>
  <v-container v-if="vueAddVeiculo">
    <v-row>
      <v-col cols="12">
        <PageToolbar
          :title="$tc('global.novo') + ' ' + $tc('pages.veiculo')"
          icon="directions_car"
          dark
          voltar
        />
      </v-col>

      <v-col cols="12">
        <v-card :loading="loading" :disabled="loading">
          <v-card-title class="subtitle-1">{{
            $tc("global.dados-gerais")
          }}</v-card-title>
          <v-card-text>
            <v-row>
              <!-- Descricao -->
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="veiculo.descricao"
                  :label="$tc('global.descricao')"
                  dense
                  filled
                  hide-details
                ></v-text-field>
              </v-col>

              <!-- Placa -->
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="veiculo.placa"
                  :label="$tc('veiculo.placa')"
                  dense
                  filled
                  hide-details
                ></v-text-field>
              </v-col>

              <!-- Cor -->
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="veiculo.cor"
                  :label="$tc('veiculo.cor')"
                  dense
                  filled
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-btn
      @click="createVeiculo"
      :disabled="!valid"
      fixed
      right
      bottom
      fab
      dark
      color="buttons"
      :class="$vuetify.breakpoint.mdAndUp ? 'mr-12' : ''"
    >
      <v-icon>save</v-icon>
    </v-btn>
  </v-container>
  <NaoAutorizado v-else />
</template>

<script>
import { postVeiculo } from "@/services/api/veiculos.api.js";
import { mapGetters } from "vuex";

export default {
  name: "NovoVeiculo",
  data: () => ({
    veiculo: {},
    loading: false,
  }),
  computed: {
    ...mapGetters("Componentes", ["getAccess"]),
    vueAddVeiculo() {
      return this.getAccess("vueVeiculo", "adicionar");
    },
    valid() {
      let valid = false;
      if (this.veiculo.descricao && this.veiculo.placa && this.veiculo.cor) {
        valid = true;
      }
      return valid;
    },
  },
  methods: {
    createVeiculo() {
      this.loading = true;
      this.veiculo.tipo = 0;
      postVeiculo(this.veiculo)
        .then((response) => {
          if (response.status === 201) {
            this.$toast.success(this.$tc("msg.criadosucesso"));
            this.veiculo = {};
          }
        })
        .catch((error) => {
          console.log(error.response);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped></style>
